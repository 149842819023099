<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M7.15497 27.845C7.46752 28.1574 7.89136 28.333 8.3333 28.333C8.77524 28.333 9.19909 28.1574 9.51164 27.845L18.3333 19.0233L27.155 27.845C27.4693 28.1486 27.8903 28.3166 28.3273 28.3128C28.7643 28.309 29.1823 28.1337 29.4913 27.8247C29.8003 27.5157 29.9756 27.0976 29.9794 26.6606C29.9832 26.2236 29.8152 25.8026 29.5116 25.4883L20.69 16.6666L29.5116 7.84497C29.6708 7.69123 29.7978 7.50732 29.8851 7.30398C29.9725 7.10064 30.0185 6.88194 30.0204 6.66064C30.0223 6.43934 29.9801 6.21988 29.8963 6.01505C29.8125 5.81022 29.6888 5.62414 29.5323 5.46765C29.3758 5.31116 29.1897 5.1874 28.9849 5.1036C28.7801 5.0198 28.5606 4.97763 28.3393 4.97956C28.118 4.98148 27.8993 5.02746 27.696 5.1148C27.4926 5.20215 27.3087 5.32912 27.155 5.48831L18.3333 14.31L9.51164 5.48831C9.1973 5.18471 8.7763 5.01672 8.3393 5.02052C7.90231 5.02431 7.48429 5.19959 7.17527 5.50861C6.86626 5.81762 6.69098 6.23564 6.68718 6.67264C6.68338 7.10963 6.85137 7.53064 7.15497 7.84497L15.9766 16.6666L7.15497 25.4883C6.84252 25.8009 6.66699 26.2247 6.66699 26.6666C6.66699 27.1086 6.84252 27.5324 7.15497 27.845Z" fill="#C4D2ED"/>
	<path d="M74.512 50.4883C74.1994 50.1759 73.7756 50.0004 73.3336 50.0004C72.8917 50.0004 72.4678 50.1759 72.1553 50.4883L63.3336 59.31L54.512 50.4883C54.1976 50.1847 53.7766 50.0168 53.3396 50.0206C52.9026 50.0243 52.4846 50.1996 52.1756 50.5086C51.8666 50.8177 51.6913 51.2357 51.6875 51.6727C51.6837 52.1097 51.8517 52.5307 52.1553 52.845L60.977 61.6667L52.1553 70.4883C51.9961 70.6421 51.8691 70.826 51.7818 71.0293C51.6944 71.2327 51.6485 71.4514 51.6465 71.6727C51.6446 71.894 51.6868 72.1134 51.7706 72.3183C51.8544 72.5231 51.9782 72.7092 52.1346 72.8657C52.2911 73.0222 52.4772 73.1459 52.682 73.2297C52.8869 73.3135 53.1063 73.3557 53.3276 73.3538C53.5489 73.3518 53.7676 73.3059 53.971 73.2185C54.1743 73.1312 54.3582 73.0042 54.512 72.845L63.3336 64.0233L72.1553 72.845C72.4696 73.1486 72.8906 73.3166 73.3276 73.3128C73.7646 73.309 74.1826 73.1337 74.4917 72.8247C74.8007 72.5157 74.976 72.0977 74.9798 71.6607C74.9836 71.2237 74.8156 70.8027 74.512 70.4883L65.6903 61.6667L74.512 52.845C74.8244 52.5325 74.9999 52.1086 74.9999 51.6667C74.9999 51.2247 74.8244 50.8009 74.512 50.4883Z" fill="#C4D2ED"/>
	<path d="M18.3335 76.6666C26.6178 76.6666 33.3335 69.9509 33.3335 61.6666C33.3335 53.3824 26.6178 46.6666 18.3335 46.6666C10.0492 46.6666 3.3335 53.3824 3.3335 61.6666C3.3335 69.9509 10.0492 76.6666 18.3335 76.6666Z" fill="#FACE5D"/>
	<path d="M75.0003 3.33331H51.667C51.3374 3.33338 51.0152 3.43117 50.7412 3.61431C50.4672 3.79745 50.2536 4.05772 50.1275 4.36222C50.0014 4.66672 49.9684 5.00178 50.0327 5.32503C50.0969 5.64829 50.2556 5.94523 50.4886 6.17831L60.977 16.6666L37.1553 40.4883C36.9961 40.6421 36.8691 40.826 36.7818 41.0293C36.6944 41.2326 36.6485 41.4513 36.6465 41.6726C36.6446 41.8939 36.6868 42.1134 36.7706 42.3182C36.8544 42.5231 36.9782 42.7091 37.1346 42.8656C37.2911 43.0221 37.4772 43.1459 37.682 43.2297C37.8869 43.3135 38.1063 43.3557 38.3276 43.3537C38.5489 43.3518 38.7676 43.3058 38.971 43.2185C39.1743 43.1311 39.3582 43.0042 39.512 42.845L63.3336 19.0233L73.822 29.5116C74.055 29.7447 74.352 29.9033 74.6752 29.9676C74.9985 30.0319 75.3336 29.9989 75.6381 29.8728C75.9426 29.7467 76.2028 29.5331 76.386 29.2591C76.5691 28.9851 76.6669 28.6629 76.667 28.3333V4.99998C76.667 4.55795 76.4914 4.13403 76.1788 3.82147C75.8662 3.50891 75.4423 3.33331 75.0003 3.33331Z" fill="#395BB6"/>
	</svg>
</template>

<script>
export default {
	name: 'Tactic',
}
</script>
