<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M57.3913 2C50.6165 2 44.2765 5.01546 40 10.1709C35.7235 5.01546 29.3835 2 22.6087 2C10.1427 2 0 12.1418 0 24.6087C0 46.0557 37.3098 76.8293 38.8995 78.1286C39.2187 78.391 39.6094 78.5217 40 78.5217C40.3906 78.5217 40.7813 78.391 41.1005 78.1286C42.6902 76.8293 80 46.0557 80 24.6087C80 12.1418 69.8573 2 57.3913 2Z" fill="#FFD764"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M24.3476 43.7391C28.1896 43.7391 31.3042 40.6246 31.3042 36.7826C31.3042 32.9406 28.1896 29.826 24.3476 29.826C20.5057 29.826 17.3911 32.9406 17.3911 36.7826C17.3911 40.6246 20.5057 43.7391 24.3476 43.7391Z" fill="#333333" fill-opacity="0.9"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M55.6523 43.7391C59.4943 43.7391 62.6088 40.6246 62.6088 36.7826C62.6088 32.9406 59.4943 29.826 55.6523 29.826C51.8103 29.826 48.6958 32.9406 48.6958 36.7826C48.6958 40.6246 51.8103 43.7391 55.6523 43.7391Z" fill="#333333" fill-opacity="0.9"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M36.522 45.4783C36.522 47.3993 38.0792 48.9565 40.0002 48.9565C41.9212 48.9565 43.4785 47.3993 43.4785 45.4783" fill="#E86C60"/>
	<path d="M36.522 45.4783C36.522 47.3993 38.0792 48.9565 40.0002 48.9565C41.9212 48.9565 43.4785 47.3993 43.4785 45.4783H36.522Z" stroke="#E86C60" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
export default {
	name: 'Heart',
}
</script>