<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M16.917 31.6047V15.7352C16.917 14.9384 17.5629 14.2925 18.3597 14.2925H24.1304C24.9272 14.2925 25.5731 14.9384 25.5731 15.7352V24.5225L16.917 31.6047Z" fill="#395BB6"/>
	<path opacity="0.664993" d="M39.9998 9.96442L15.4741 31.765V69.1139C15.4741 70.7074 16.766 71.9993 18.3595 71.9993H61.6401C63.2337 71.9993 64.5255 70.7074 64.5255 69.1139V31.765L39.9998 9.96442Z" fill="#E6E6E6"/>
	<path d="M71.7386 40.2602C71.3928 40.2602 71.0455 40.1362 70.7686 39.8854L40.0002 11.9136L9.2318 39.8854C8.64148 40.4208 7.72924 40.3757 7.19386 39.7882C6.65779 39.1979 6.70075 38.2863 7.29036 37.7496L39.0295 8.8958C39.5804 8.39706 40.4201 8.39706 40.9709 8.8958L72.7101 37.7496C73.2997 38.2863 73.3427 39.1979 72.8066 39.7882C72.522 40.101 72.131 40.2602 71.7386 40.2602Z" fill="#5D7BC4"/>
	<path d="M45.7708 53.2451H34.2293C33.4325 53.2451 32.7866 53.891 32.7866 54.6877V72H47.2135V54.6877C47.2135 53.891 46.5676 53.2451 45.7708 53.2451Z" fill="#FFD764"/>
	<path d="M40.0002 44.5889C43.1873 44.5889 45.771 42.0053 45.771 38.8182C45.771 35.6311 43.1873 33.0474 40.0002 33.0474C36.8131 33.0474 34.2295 35.6311 34.2295 38.8182C34.2295 42.0053 36.8131 44.5889 40.0002 44.5889Z" fill="#8AA5DB"/>
	<path d="M26.0263 72C26.6534 70.6873 27.016 69.2241 27.016 67.6719C27.016 62.0945 22.4946 57.5731 16.9172 57.5731C11.3398 57.5731 6.81836 62.0945 6.81836 67.6719C6.81836 69.2241 7.18096 70.6873 7.808 72H26.0263Z" fill="#C4D2ED"/>
	<path d="M72.1924 72C72.8194 70.6873 73.182 69.2241 73.182 67.6719C73.182 62.0945 68.6607 57.5731 63.0832 57.5731C57.5058 57.5731 52.9844 62.0945 52.9844 67.6719C52.9844 69.2241 53.347 70.6873 53.974 72H72.1924Z" fill="#C4D2ED"/>
	</svg>
</template>

<script>
	export default {
		name: 'Home',
	}
</script>