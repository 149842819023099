<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M46.6665 41.6666H33.3332C32.4128 41.6666 31.6665 42.4129 31.6665 43.3333V73.3333C31.6665 74.2537 32.4128 75 33.3332 75H46.6665C47.5869 75 48.3332 74.2537 48.3332 73.3333V43.3333C48.3332 42.4129 47.5869 41.6666 46.6665 41.6666Z" fill="#5D7BC4"/>
	<path d="M20 55H6.66667C5.74625 55 5 55.7462 5 56.6667V73.3333C5 74.2537 5.74625 75 6.66667 75H20C20.9204 75 21.6667 74.2537 21.6667 73.3333V56.6667C21.6667 55.7462 20.9204 55 20 55Z" fill="#8AA5DB"/>
	<path d="M73.3335 28.3334H60.0002C59.0797 28.3334 58.3335 29.0796 58.3335 30V73.3334C58.3335 74.2538 59.0797 75 60.0002 75H73.3335C74.2539 75 75.0002 74.2538 75.0002 73.3334V30C75.0002 29.0796 74.2539 28.3334 73.3335 28.3334Z" fill="#395BB6"/>
	<path d="M41.1784 31.1784L61.6667 10.6901V20C61.6667 20.9204 62.4129 21.6667 63.3333 21.6667C64.2537 21.6667 65 20.9204 65 20V6.66667C65 6.44958 64.9556 6.23352 64.8713 6.02977C64.7023 5.62235 64.3776 5.29765 63.9702 5.12868C63.7665 5.04435 63.5504 5 63.3333 5H50C49.0796 5 48.3333 5.74625 48.3333 6.66667C48.3333 7.58708 49.0796 8.33333 50 8.33333H59.3099L40 27.6432L31.1784 18.8216C30.5273 18.1706 29.4726 18.1706 28.8216 18.8216L15.4883 32.155C14.8372 32.806 14.8372 33.8607 15.4883 34.5117C15.8138 34.8372 16.2402 35 16.6667 35C17.0931 35 17.5195 34.8372 17.845 34.5117L30 22.3568L38.8216 31.1784C39.4726 31.8294 40.5273 31.8294 41.1784 31.1784Z" fill="#FACE5D"/>
	</svg>
</template>

<script>
export default {
	name: 'Chart',
}
</script>
