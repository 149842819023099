<template>
  <div class="exp-card">
    <div class="exp-card__illustration">
      <slot name="illustration"></slot>
    </div>
    <div class="exp-card__title">
      <slot name="title"></slot>
    </div>
    <div class="exp-card__text">
      <slot name="text"></slot>
    </div>
  </div>
</template>

<style lang="scss">
  .exp-card {
    padding: 25px 15px;
    vertical-align: top;
    text-align: center;
    @media (min-width: $breakpoint-large) {
      width: 25%;
      display: inline-block;
      padding: 15px;
    }
    &__illustration {
      height: 70px;
      width: 70px;
      display: block;
      margin: auto;
    }
    &__title {
      margin-top: 25px;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 24px;
      @media (min-width: $breakpoint-large) {
        margin-top: 45px;
      }
    }
    &__text {
      font-family: 'Montserrat';
    }
  }
</style>

<script>
  export default {
    name: 'Card',
  }
</script>