<template>
	<svg viewBox="0 0 68 63" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M62.087 44.8261H5.91306C4.28022 44.8261 2.95654 46.1498 2.95654 47.7826V59.6087C2.95654 61.2415 4.28022 62.5652 5.91306 62.5652H62.087C63.7198 62.5652 65.0435 61.2415 65.0435 59.6087V47.7826C65.0435 46.1498 63.7198 44.8261 62.087 44.8261Z" fill="#395BB6"/>
	<path d="M45.8257 12.3044C45.0087 12.3044 44.3475 11.6425 44.3475 10.8261V3.43479H23.6518V10.8261C23.6518 11.6425 22.9907 12.3044 22.1736 12.3044C21.3565 12.3044 20.6953 11.6425 20.6953 10.8261V1.95652C20.6953 1.14016 21.3565 0.478264 22.1736 0.478264H45.8257C46.6428 0.478264 47.304 1.14016 47.304 1.95652V10.8261C47.304 11.6425 46.6428 12.3044 45.8257 12.3044Z" fill="#5D7BC4"/>
	<path d="M65.0435 9.34783H2.95652C1.32368 9.34783 0 10.6715 0 12.3044V44.8261C0 46.4589 1.32368 47.7826 2.95652 47.7826H65.0435C66.6763 47.7826 68 46.4589 68 44.8261V12.3044C68 10.6715 66.6763 9.34783 65.0435 9.34783Z" fill="#5D7BC4"/>
	<path d="M39.9127 53.6957H28.0867C27.2702 53.6957 26.6084 53.0338 26.6084 52.2174V43.3478C26.6084 42.5314 27.2702 41.8696 28.0867 41.8696H39.9127C40.7292 41.8696 41.391 42.5314 41.391 43.3478V52.2174C41.391 53.0338 40.7292 53.6957 39.9127 53.6957Z" fill="#FFD764"/>
	</svg>
</template>

<script>
export default {
	name: 'Briefcase',
}
</script>
