<template>
  <div class="exp-ellipse-container">
    <slot></slot>
  </div>
</template>

<style lang="scss">
  .exp-ellipse-container {
    background: url('../../assets/img/fallbackEllipse.png');
    background-image: url('../../assets/img/ellipse.svg'), none;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
</style>

<script>
  export default {
    name: 'EllipseContainer',
  }
</script>