<template>
  <div>
    <Layout>
      <PopupContact :popupOpen="popupContactOpen" :closeFunction="popupContactTrigger" popupId="landing"/>

      <EllipseContainer>
        <Container>
          <SplitLayout>
            <template v-slot:left>
              <img src="../assets/img/landingScene.png" alt="landing scene illustrated people" class="exp-landing__picture">
            </template>
            <template v-slot:right>
              <div class="exp-landing__container">
                <h1 class="exp-title-shrink">Expert-comptable</h1>
                <p class="exp-landing__subtitle exp-subtitle-shrink">
                  Nous sommes compétents et proactifs.
                  <span class="exp-subtitle-mobile-justify">Nous vous accompagnons dans le développement de vos projets.</span>
                </p>
                <h2 class="exp-landing__second-title">
                  Une équipe <span class="exp-color-yellow">réactive</span> et <span class="exp-color-yellow">impliquée.</span>
                </h2>
                <ExpBtn type="primary" @click.native="popupContactTrigger" additionalClass="exp-margin-top-30">
                  Nous contacter
                </ExpBtn>
              </div>
            </template>
          </SplitLayout>
        </Container>
      </EllipseContainer>

      <ExpArticle>
        <Card v-for='item in firstArticleItems' v-bind:key="item.title">
          <template v-slot:illustration>
            <component v-bind:is="`${item.icon}`"></component>
          </template>
          <template v-slot:title>
            {{ item.title }}
          </template>
          <template v-slot:text>
            {{ item.text }}
          </template>
        </Card>
      </ExpArticle>

      <BlueBackground class="exp-color-white">
        <Container>
          <SplitLayout>
              <template v-slot:left>
                <div class="exp-landing__title">
                  Une question, un besoin ? <br>
                  Contactez-nous pour en parler !
                </div>
                <p class="exp-landing__subtitle">
                  Notre équipe est à l’écoute de vos besoins et vous proposera les services adaptés à votre situation.
                </p>
                <ExpBtn type="inverted" @click.native="popupContactTrigger">
                  Nous contacter
                </ExpBtn>
              </template>
              <template v-slot:right>
                <img src="../assets/img/phoneScene.png" alt="illustrated people communicating" class="exp-landing__picture bottom-picture">
              </template>
          </SplitLayout>
        </Container>
      </BlueBackground>

      <ExpArticle>
        <Card v-for='item in secondArticleItems' v-bind:key="item.title">
          <template v-slot:illustration>
            <component v-bind:is="`${item.icon}`"></component>
          </template>
          <template v-slot:title>
            {{ item.title }}
          </template>
          <template v-slot:text>
            {{ item.text }}
          </template>
        </Card>
      </ExpArticle>
      <ExpArticle class="exp-landing__article-mobile-squeeze">
        <Card v-for='item in thirdArticleItems' v-bind:key="item.title">
          <template v-slot:illustration>
            <component v-bind:is="`${item.icon}`"></component>
          </template>
          <template v-slot:title>
            {{ item.title }}
          </template>
          <template v-slot:text>
            {{ item.text }}
          </template>
        </Card>
      </ExpArticle>
    </Layout>
  </div>
</template>

<style lang="scss">
  .exp-title-shrink {
    @media (max-width: $breakpoint-small - 1) {
      font-size: 33px;
    }
  }
  .exp-subtitle-shrink {
    @media (max-width: $breakpoint-small - 1) {
      font-size: 17px;
    }
  }
  .exp-subtitle-mobile-justify {
    @media (max-width: $breakpoint-small - 1) {
      text-align: justify;
      display: block;
    }
  }
  .exp-landing{
    &__container {
      margin-top: -30px;
      @media (min-width: $breakpoint-large) {
        margin-top: 0;
        padding-left: 95px;
        max-width: 520px;
      }
    }
    &__title {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
    }
    &__subtitle {
      margin-bottom: 30px;
    }
    &__second-title {
      font-size: 30px;
    }
    &__article-mobile-squeeze {
      @media (max-width: $breakpoint-large - 1) {
        margin-top: -150px;
      }
    }
    &__picture {
      width: 100%;
      max-width: 400px;
      margin: auto;
      display: block;
      @media (min-width: $breakpoint-large) {
        max-width: 100%;
        margin: 0;
        padding-bottom: 30px;
      }
      &.bottom-picture {
        margin-bottom: -40px;
        margin-top: -40px;
        margin-right: 0;
        @media (min-width: $breakpoint-large) {
          margin: 0;
          position: absolute;
          right: 0;
          bottom: 0;
          max-width: 485px;
          padding: 0;
        }
      }
    }
  }
</style>

<script>
import BlueBackground from '../components/layout/BlueBackground.vue';
import Card from '../components/Card.vue';
import Container from '../components/layout/Container.vue';
import EllipseContainer from '../components/layout/EllipseContainer.vue';
import ExpArticle from '../components/layout/Article.vue';
import ExpBtn from '../components/Button.vue';
import Layout from '../components/layout/Layout.vue';
import PopupContact from '../pages/PopupContact.vue';
import SplitLayout from '../components/layout/SplitLayout.vue';
/* SVG */
import Briefcase from '../assets/svg/briefcase.vue';
import Bulb from '../assets/svg/bulb.vue';
import Calculator from '../assets/svg/calculator.vue';
import Chart from '../assets/svg/chart.vue';
import Chat from '../assets/svg/chat.vue';
import Check from '../assets/svg/check.vue';
import Geometry from '../assets/svg/geometry.vue';
import Hammer from '../assets/svg/hammer.vue';
import Heart from '../assets/svg/heart.vue';
import Home from '../assets/svg/home.vue';
import Measure from '../assets/svg/measure.vue';
import Pig from '../assets/svg/pig.vue';
import Puzzle from '../assets/svg/puzzle.vue';
import Tactic from '../assets/svg/tactic.vue';

export default {
  name: 'Landing',

  components: {
    BlueBackground,
    Card,
    Container,
    EllipseContainer,
    ExpArticle,
    ExpBtn,
    Layout,
    PopupContact,
    SplitLayout,
    /* SVG */
    Briefcase,
    Bulb,
    Pig,
    Calculator,
    Chart,
    Chat,
    Check,
    Geometry,
    Hammer,
    Heart,
    Home,
    Measure,
    Puzzle,
    Tactic,
  },

  data() {
    return {
      popupContactOpen: false,
      firstArticleItems: [
        {
          icon: 'Briefcase',
          title: 'Professionnels',
          text: 'Nous étudions votre stratégie et analysons vos données chiffrées pour accroître votre rentabilité.',
        },
        {
          icon: 'Pig',
          title: 'Particuliers',
          text: 'Nous décortiquons les solutions les plus avantageuses pour payer moins d’impôts.',
        },
        {
          icon: 'Home',
          title: 'Immobilier',
          text: 'Nous construisons votre stratégie fiscale pour vos investissements immobiliers.',
        },
        {
          icon: 'Bulb',
          title: 'Innovation',
          text: 'Nous anticipons votre croissance et vos besoins de financement.',
        }
      ],
      secondArticleItems: [
        {
          icon: 'Measure',
          title: 'Super carrés',
          text: 'Rigoureux, précis, nous respectons vos échéances.',
        },
        {
          icon: 'Chat',
          title: 'À l’écoute',
          text: 'Nous prenons le temps de comprendre votre projet et vos enjeux.',
        },
        {
          icon: 'Geometry',
          title: 'Simples',
          text: 'Nous vous expliquons les choses de manière claire et pédagogique.',
        },
        {
          icon: 'Puzzle',
          title: 'Collaboratifs',
          text: 'Nous sélectionnons des partenaires pour vous en faire profiter.',
        }
      ],
      thirdArticleItems: [
        {
          icon: 'Heart',
          title: 'Passionnés',
          text: 'Nous aimons notre métier et nous ne le cachons pas.',
        },
        {
          icon: 'Hammer',
          title: 'Savoir-faire',
          text: 'Nous nous formons constamment pour vous offrir le meilleur.',
        },
        {
          icon: 'Chart',
          title: 'Productivité',
          text: 'Nous investissons dans les outils les plus performants pour vous faire gagner du temps.',
        },
        {
          icon: 'Tactic',
          title: 'Agilité',
          text: 'Nous sommes flexibles et réagissons vite pour anticiper les événements.',
        }
      ],
    };
  },

  methods: {
    popupContactTrigger() {
      this.popupContactOpen = !this.popupContactOpen;
    },
  },
}
</script>