<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M38.3809 14.2573V6.00012H10.8571C9.56894 6.00012 8.33351 6.51185 7.42262 7.42274C6.51173 8.33363 6 9.56907 6 10.8573V38.381H15.7143C15.9283 38.381 16.1401 38.4234 16.3376 38.5058C16.5351 38.5882 16.7143 38.7089 16.8649 38.861C17.0154 39.013 17.1344 39.1934 17.2148 39.3917C17.2952 39.59 17.3355 39.8023 17.3333 40.0163C17.3481 40.9452 17.6234 41.8513 18.1278 42.6315C18.6322 43.4117 19.3455 44.0346 20.1864 44.4294C21.0274 44.8241 21.9624 44.9749 22.8848 44.8645C23.8073 44.7541 24.6802 44.3869 25.4043 43.8048C25.9748 43.37 26.4251 42.7969 26.7126 42.1397C27.0002 41.4825 27.1154 40.7628 27.0476 40.0487C27.0411 39.832 27.0782 39.6162 27.1566 39.4141C27.2351 39.2121 27.3533 39.0278 27.5043 38.8723C27.6553 38.7168 27.836 38.5931 28.0356 38.5088C28.2353 38.4244 28.4499 38.3809 28.6666 38.381H38.3809V30.1239C36.5509 29.7523 34.9057 28.7595 33.7239 27.3137C32.5422 25.8678 31.8966 24.0579 31.8966 22.1906C31.8966 20.3232 32.5422 18.5133 33.7239 17.0675C34.9057 15.6217 36.5509 14.6289 38.3809 14.2573Z" fill="#395BB6"/>
	<path d="M14.2571 41.619H6V69.1428C6 70.431 6.51173 71.6664 7.42262 72.5773C8.33351 73.4882 9.56894 73.9999 10.8571 73.9999H38.3809V64.2857C38.3809 64.0717 38.4233 63.8598 38.5057 63.6623C38.5881 63.4648 38.7088 63.2856 38.8608 63.1351C39.0129 62.9845 39.1933 62.8656 39.3916 62.7852C39.5899 62.7048 39.8022 62.6645 40.0162 62.6666C40.9451 62.6518 41.8512 62.3766 42.6314 61.8722C43.4116 61.3678 44.0345 60.6545 44.4292 59.8135C44.824 58.9725 44.9748 58.0376 44.8644 57.1151C44.754 56.1927 44.3868 55.3197 43.8047 54.5957C43.3699 54.0251 42.7968 53.5748 42.1396 53.2873C41.4823 52.9998 40.7627 52.8845 40.0485 52.9523C39.8319 52.9588 39.6161 52.9218 39.414 52.8433C39.212 52.7649 39.0277 52.6466 38.8722 52.4957C38.7166 52.3447 38.593 52.164 38.5086 51.9643C38.4243 51.7646 38.3808 51.5501 38.3809 51.3333V41.619H30.1238C29.7522 43.449 28.7594 45.0943 27.3135 46.276C25.8677 47.4578 24.0578 48.1033 22.1905 48.1033C20.3231 48.1033 18.5132 47.4578 17.0674 46.276C15.6216 45.0943 14.6287 43.449 14.2571 41.619Z" fill="#8AA5DB"/>
	<path d="M65.743 38.381H74.0001V10.8572C74.0001 9.56901 73.4884 8.33357 72.5775 7.42268C71.6666 6.51179 70.4312 6.00006 69.143 6.00006H41.6192V15.7143C41.6192 15.9283 41.5768 16.1402 41.4944 16.3377C41.4121 16.5352 41.2913 16.7144 41.1393 16.8649C40.9872 17.0155 40.8068 17.1344 40.6085 17.2148C40.4102 17.2952 40.1979 17.3355 39.984 17.3334C39.055 17.3482 38.1489 17.6234 37.3688 18.1278C36.5886 18.6322 35.9656 19.3455 35.5709 20.1865C35.1761 21.0275 35.0253 21.9624 35.1358 22.8849C35.2462 23.8073 35.6133 24.6803 36.1954 25.4043C36.6303 25.9749 37.2033 26.4252 37.8606 26.7127C38.5178 27.0002 39.2374 27.1155 39.9516 27.0477C40.1683 27.0412 40.384 27.0782 40.5861 27.1567C40.7882 27.2351 40.9724 27.3533 41.128 27.5043C41.2835 27.6553 41.4071 27.836 41.4915 28.0357C41.5759 28.2354 41.6193 28.4499 41.6192 28.6667V38.381H49.8763C50.2479 36.551 51.2408 34.9057 52.6866 33.724C54.1324 32.5422 55.9423 31.8967 57.8097 31.8967C59.677 31.8967 61.4869 32.5422 62.9327 33.724C64.3786 34.9057 65.3714 36.551 65.743 38.381Z" fill="#FACE5D"/>
	<path d="M41.6191 65.7429V74H69.1429C70.4311 74 71.6665 73.4883 72.5774 72.5774C73.4883 71.6665 74.0001 70.431 74.0001 69.1429V41.6191H64.2858C64.0718 41.6191 63.8599 41.5767 63.6624 41.4943C63.4649 41.4119 63.2857 41.2912 63.1352 41.1392C62.9846 40.9871 62.8657 40.8067 62.7853 40.6084C62.7049 40.4101 62.6646 40.1978 62.6667 39.9838C62.6519 39.0549 62.3767 38.1488 61.8723 37.3686C61.3679 36.5884 60.6546 35.9655 59.8136 35.5708C58.9726 35.176 58.0377 35.0252 57.1153 35.1356C56.1928 35.246 55.3199 35.6132 54.5958 36.1953C54.0253 36.6301 53.575 37.2032 53.2874 37.8604C52.9999 38.5177 52.8846 39.2373 52.9525 39.9515C52.959 40.1681 52.9219 40.3839 52.8434 40.586C52.765 40.788 52.6468 40.9723 52.4958 41.1278C52.3448 41.2834 52.1641 41.407 51.9644 41.4914C51.7648 41.5757 51.5502 41.6192 51.3334 41.6191H41.6191V49.8762C43.4491 50.2478 45.0944 51.2406 46.2761 52.6865C47.4579 54.1323 48.1034 55.9422 48.1034 57.8095C48.1034 59.6769 47.4579 61.4868 46.2761 62.9326C45.0944 64.3784 43.4491 65.3713 41.6191 65.7429Z" fill="#E1E8F6"/>
	</svg>
</template>

<script>
export default {
	name: 'Puzzle',
}
</script>