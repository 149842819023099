<template>
  <div class="exp-blue-background">
    <slot></slot>
  </div>
</template>

<style lang="scss">
  .exp-blue-background {
    background: linear-gradient(206.57deg, rgba(161, 184, 230, 0.8) 16.67%, rgba(117, 146, 204, 0.8) 100%), $blue;
  }
</style>

<script>
  export default {
    name: 'BlueBackground',
  }
</script>