<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M9.99984 41.6667H3.33317C2.41275 41.6667 1.6665 40.9212 1.6665 40C1.6665 39.0788 2.41275 38.3333 3.33317 38.3333H9.99984C10.9203 38.3333 11.6665 39.0788 11.6665 40C11.6665 40.9212 10.9203 41.6667 9.99984 41.6667Z" fill="#FFD764"/>
	<path d="M18.7864 20.4541C18.36 20.4541 17.9336 20.2913 17.6081 19.9658L12.8945 15.2507C12.2435 14.5996 12.2435 13.5449 12.8945 12.8939C13.5456 12.2429 14.6003 12.2429 15.2513 12.8939L19.9648 17.6091C20.6159 18.2601 20.6159 19.3148 19.9648 19.9658C19.6393 20.2913 19.2129 20.4541 18.7864 20.4541Z" fill="#FFD764"/>
	<path d="M39.9997 11.6667C39.0793 11.6667 38.333 10.9212 38.333 10V3.33333C38.333 2.41211 39.0793 1.66666 39.9997 1.66666C40.9201 1.66666 41.6663 2.41211 41.6663 3.33333V10C41.6663 10.9212 40.9201 11.6667 39.9997 11.6667Z" fill="#FFD764"/>
	<path d="M61.2135 20.4541C60.7871 20.4541 60.3607 20.2913 60.0351 19.9658C59.3841 19.3148 59.3841 18.2601 60.0351 17.6091L64.7487 12.8939C65.3981 12.2429 66.4544 12.2429 67.1054 12.8939C67.7565 13.5449 67.7565 14.5996 67.1054 15.2507L62.3919 19.9658C62.0672 20.2913 61.64 20.4541 61.2135 20.4541Z" fill="#FFD764"/>
	<path d="M76.6663 41.6667H69.9997C69.0793 41.6667 68.333 40.9212 68.333 40C68.333 39.0788 69.0793 38.3333 69.9997 38.3333H76.6663C77.5868 38.3333 78.333 39.0788 78.333 40C78.333 40.9212 77.5868 41.6667 76.6663 41.6667Z" fill="#FFD764"/>
	<path d="M48.333 68.3333H31.6663V59.9805C22.7701 56.2696 17.2083 46.9787 18.5254 37.1148C19.9429 26.499 29.2805 18.3378 39.9904 18.3333C51.9421 18.3283 61.6663 28.0503 61.6663 40C61.6663 48.75 56.3555 56.6341 48.333 59.9805V68.3333Z" fill="#FFD764"/>
	<path d="M39.9999 78.3333H39.9998C35.3975 78.3333 31.6665 74.6024 31.6665 70V65H48.3332V70C48.3332 74.6024 44.6022 78.3333 39.9999 78.3333Z" fill="#C4D2ED"/>
	<path d="M48.3332 65H31.6665V68.3333H48.3332V65Z" fill="#5D7BC4"/>
	</svg>
</template>

<script>
	export default {
		name: 'Bulb',
	}
</script>