<template>
  <div class="exp-article">
    <Container>
      <slot></slot>
    </Container>
  </div>
</template>

<style lang="scss">
  .exp-article {
    padding: 100px 0;
    @media (min-width: $breakpoint-large) {
      padding: 100px 0;
    }
  }
</style>

<script>
import Container from './Container.vue';

  export default {
    name: 'ExpArticle',

    components: {
      Container,
    }
  }
</script>