<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M71.3755 18.5093H27.4107C25.8435 18.5093 24.5742 19.7786 24.5742 21.3457V55.383C24.5742 56.9501 25.8435 58.2194 27.4107 58.2194H43.9996L60.6608 69.3269C60.8977 69.4843 61.1728 69.5652 61.4479 69.5652C61.6777 69.5652 61.9074 69.5099 62.1173 69.3978C62.5782 69.1511 62.8661 68.6703 62.8661 68.147V58.2194H71.3755C72.9426 58.2194 74.2119 56.9501 74.2119 55.383V21.3457C74.2119 19.7786 72.9426 18.5093 71.3755 18.5093Z" fill="#FACE5D"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M56.803 10H8.58351C7.01638 10 5.74707 11.2693 5.74707 12.8364V46.8737C5.74707 48.4408 7.01638 49.7101 8.58351 49.7101H17.0928V62.4741C17.0928 63.006 17.3892 63.4924 17.8615 63.7363C18.0671 63.8413 18.2898 63.8923 18.511 63.8923C18.8018 63.8923 19.0897 63.8044 19.335 63.6271L38.82 49.7101H56.803C58.3701 49.7101 59.6394 48.4408 59.6394 46.8737V12.8364C59.6394 11.2693 58.3701 10 56.803 10Z" fill="#395BB6"/>
	<path d="M18.0928 49.7101V48.7101H17.0928H8.58351C7.56866 48.7101 6.74707 47.8886 6.74707 46.8737V12.8364C6.74707 11.8216 7.56866 11 8.58351 11H56.803C57.8178 11 58.6394 11.8216 58.6394 12.8364V46.8737C58.6394 47.8886 57.8178 48.7101 56.803 48.7101H38.82H38.4995L38.2387 48.8964L18.7538 62.8134L18.7494 62.8166C18.6804 62.8664 18.599 62.8923 18.511 62.8923C18.4446 62.8923 18.379 62.8773 18.3181 62.8467C18.1795 62.774 18.0928 62.631 18.0928 62.4741V49.7101Z" stroke="black" stroke-opacity="0.1" stroke-width="2"/>
	</svg>
</template>

<script>
export default {
	name: 'Chat',
}
</script>