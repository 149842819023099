<template>
	<svg fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M71.1782 72.155L4.5115 5.48834C4.03317 5.01001 3.3165 4.86834 2.69484 5.12668C2.07317 5.38501 1.6665 5.99334 1.6665 6.66668V73.3333C1.6665 74.2533 2.41317 75 3.33317 75H69.9998C70.6732 75 71.2815 74.5933 71.5398 73.9717C71.7982 73.3483 71.6548 72.6317 71.1782 72.155ZM38.2065 60.6383C37.9482 61.26 37.3398 61.6667 36.6665 61.6667H19.9998C19.0798 61.6667 18.3332 60.92 18.3332 60V43.3333C18.3332 42.66 18.7398 42.0517 19.3615 41.7933C19.9848 41.5333 20.6998 41.6767 21.1782 42.155L37.8448 58.8217C38.3215 59.2983 38.4648 60.015 38.2065 60.6383Z" fill="#FACE5D"/>
	<path d="M8.33317 33.3334H1.6665V36.6667H8.33317V33.3334Z" fill="#333333" fill-opacity="0.3"/>
	<path d="M8.33317 23.3334H1.6665V26.6667H8.33317V23.3334Z" fill="#333333" fill-opacity="0.3"/>
	<path d="M8.33317 43.3334H1.6665V46.6667H8.33317V43.3334Z" fill="#333333" fill-opacity="0.3"/>
	<path d="M8.33317 53.3334H1.6665V56.6667H8.33317V53.3334Z" fill="#333333" fill-opacity="0.3"/>
	<path d="M8.33317 63.3334H1.6665V66.6667H8.33317V63.3334Z" fill="#333333" fill-opacity="0.3"/>
	</svg>
</template>

<script>
	export default {
		name: 'Measure',
	}
</script>