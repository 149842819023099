<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M27.2728 48H1.81823C1.26781 48 0.747557 48.2431 0.401321 48.6632C0.0568665 49.0816 -0.0763153 49.6319 0.0426484 50.158C0.184703 50.7951 3.80687 65.7778 27.2728 65.7778C28.2777 65.7778 29.091 64.9826 29.091 64V49.7778C29.091 48.7951 28.2777 48 27.2728 48Z" fill="#5D7BC4"/>
	<path d="M74.013 30.7431L57.6494 14.7431C56.9391 14.0486 55.7886 14.0486 55.0783 14.7431L47.8056 21.8542C47.0954 22.5486 47.0954 23.6736 47.8056 24.3681L64.1692 40.3681C64.5244 40.7153 64.9896 40.8889 65.4548 40.8889C65.92 40.8889 66.3852 40.7153 66.7403 40.3681L74.013 33.2569C74.7232 32.5625 74.7232 31.4375 74.013 30.7431Z" fill="#FFD764"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M78.1819 48H25.4546V78.2222C25.4546 79.2041 26.2686 80 27.2728 80H67.2728C68.3011 80 69.0845 79.1695 69.0912 78.164C69.1232 73.309 73.157 69.3648 78.1223 69.3335C79.1506 69.3271 80 68.561 80 67.5556V49.7778C80 48.7959 79.186 48 78.1819 48Z" fill="#395BB6"/>
	<path d="M26.4546 78.2222V49H78.1819C78.655 49 79 49.3693 79 49.7778V67.5556C79 67.9414 78.6671 68.3301 78.116 68.3336C72.6243 68.3682 68.1269 72.7345 68.0912 78.1574C68.088 78.6427 67.7224 79 67.2728 79H27.2728C26.7996 79 26.4546 78.6307 26.4546 78.2222Z" stroke="black" stroke-opacity="0.1" stroke-width="2"/>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M64.9216 7.63194L57.6489 0.520827C56.9387 -0.173609 55.7881 -0.173609 55.0779 0.520827L33.2597 21.8542C32.5495 22.5486 32.5495 23.6736 33.2597 24.368L40.5324 31.4792C40.8876 31.8264 41.3528 32 41.818 32C42.2832 32 42.7484 31.8264 43.1035 31.4792L64.9216 10.1458C65.6319 9.45139 65.6319 8.32637 64.9216 7.63194Z" fill="#395BB6"/>
	<path d="M33.9588 22.5692L33.2671 21.8617L33.9588 22.5692L55.777 1.23583C56.0986 0.921389 56.6282 0.921389 56.9498 1.23583L64.2225 8.34694L64.9216 7.63194L64.2225 8.34695C64.5316 8.64913 64.5316 9.12864 64.2225 9.43081L64.9216 10.1458L64.2225 9.43082L42.4043 30.7641C42.2457 30.9193 42.0356 31 41.818 31C41.6003 31 41.3902 30.9193 41.2316 30.7642L33.9588 23.653L33.2597 24.368L33.9588 23.653C33.6498 23.3509 33.6498 22.8713 33.9588 22.5692Z" stroke="black" stroke-opacity="0.1" stroke-width="2"/>
	<path d="M20.0001 40.8889H12.7274C11.7224 40.8889 10.9092 40.0938 10.9092 39.1112C10.9092 38.1285 11.7224 37.3334 12.7274 37.3334H20.0001C21.0051 37.3334 21.8183 38.1285 21.8183 39.1112C21.8183 40.0938 21.0051 40.8889 20.0001 40.8889Z" fill="#FFD764"/>
	<path d="M24.2595 30.8316C23.7943 30.8316 23.3291 30.658 22.974 30.3108L17.832 25.283C17.1218 24.5886 17.1218 23.4636 17.832 22.7691C18.5422 22.0747 19.6928 22.0747 20.403 22.7691L25.545 27.7969C26.2553 28.4913 26.2553 29.6163 25.545 30.3108C25.1899 30.658 24.7247 30.8316 24.2595 30.8316Z" fill="#FFD764"/>
	</svg>
</template>

<script>
export default {
	name: 'Hammer',
}
</script>