<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M61.6667 43.3333C70.8714 43.3333 78.3333 35.8714 78.3333 26.6667C78.3333 17.4619 70.8714 10 61.6667 10C52.4619 10 45 17.4619 45 26.6667C45 35.8714 52.4619 43.3333 61.6667 43.3333Z" fill="#8AA5DB"/>
	<path d="M44.9998 45H18.3332C17.4127 45 16.6665 45.7462 16.6665 46.6667V73.3333C16.6665 74.2538 17.4127 75 18.3332 75H44.9998C45.9203 75 46.6665 74.2538 46.6665 73.3333V46.6667C46.6665 45.7462 45.9203 45 44.9998 45Z" fill="#FACE5D"/>
	<path d="M38.1163 34.155C38.2618 33.8981 38.3365 33.6072 38.3327 33.312C38.3289 33.0169 38.2468 32.728 38.0947 32.475L23.0947 7.47497C22.9354 7.24328 22.7221 7.05382 22.4733 6.92292C22.2245 6.79203 21.9475 6.72363 21.6663 6.72363C21.3852 6.72363 21.1082 6.79203 20.8594 6.92292C20.6106 7.05382 20.3973 7.24328 20.238 7.47497L5.23802 32.475C5.08609 32.7278 5.00401 33.0165 5.00014 33.3115C4.99628 33.6064 5.07077 33.8971 5.21601 34.1539C5.36126 34.4107 5.57205 34.6243 5.82685 34.7729C6.08166 34.9216 6.37136 34.9999 6.66635 35H36.6663C36.9612 35 37.2507 34.9217 37.5055 34.7733C37.7602 34.6248 37.971 34.4115 38.1163 34.155Z" fill="#395BB6"/>
	</svg>
</template>

<script>
export default {
	name: 'Geometry',
}
</script>