<template>
	<svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M71.3046 52.3692C70.3441 52.3692 69.5654 51.5914 69.5654 50.6301C69.5654 49.6688 70.3441 48.891 71.3046 48.891C73.6848 48.891 76.522 47.9857 76.522 43.6736C76.522 40.8254 74.7522 40.2412 74.3973 40.1511C73.4785 39.9236 72.8934 38.9963 73.1048 38.0723C73.3154 37.1484 74.2062 36.554 75.1378 36.7544C76.82 37.1043 80.0002 39.0014 80.0002 43.6736C80.0002 48.9555 76.5873 52.3692 71.3046 52.3692Z" fill="#8AA5DB"/>
	<path d="M50.4348 27.8261H30.6802C26.5769 19.5669 18.7704 19.6841 15.0798 20.9664C14.1814 21.2789 13.7016 22.2554 14.0022 23.159L16.8818 31.7986C13.2379 34.2799 10.3923 37.7446 8.71689 41.7391H1.73913C0.778696 41.7391 0 42.517 0 43.4783V60.8696C0 61.8308 0.778696 62.6087 1.73913 62.6087H10.5868C13.0876 66.498 16.6814 69.5279 20.8696 71.284V78.2609C20.8696 79.2221 21.6483 80 22.6087 80H33.0435C34.0039 80 34.7826 79.2221 34.7826 78.2609V73.0435H45.2174V78.2609C45.2174 79.2221 45.9961 80 46.9565 80H57.3913C58.3517 80 59.1304 79.2221 59.1304 78.2609V71.284C67.5017 67.7921 73.0435 59.5652 73.0435 50.4348C73.0435 37.9688 62.9017 27.8261 50.4348 27.8261Z" fill="#C4D2ED"/>
	<path d="M50.4349 38.2609H36.5218C35.5614 38.2609 34.7827 37.483 34.7827 36.5217C34.7827 35.5605 35.5614 34.7826 36.5218 34.7826H50.4349C51.3953 34.7826 52.174 35.5605 52.174 36.5217C52.174 37.483 51.3953 38.2609 50.4349 38.2609Z" fill="#395BB6"/>
	<path d="M43.4784 17.3913C48.2808 17.3913 52.174 13.4981 52.174 8.69565C52.174 3.89318 48.2808 0 43.4784 0C38.6759 0 34.7827 3.89318 34.7827 8.69565C34.7827 13.4981 38.6759 17.3913 43.4784 17.3913Z" fill="#FACE5D"/>
	<path d="M22.6086 46.9565C20.6903 46.9565 19.1304 45.3957 19.1304 43.4783C19.1304 41.5608 20.6903 40 22.6086 40C24.5269 40 26.0869 41.5608 26.0869 43.4783C26.0869 45.3957 24.5269 46.9565 22.6086 46.9565Z" fill="#8AA5DB"/>
	</svg>
</template>

<script>
	export default {
		name: 'Pig',
	}
</script>